import React from 'react'
import { graphql, Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import styled from 'styled-components'
import Layout from '../components/layouts'
import PostSection from '../components/blog/PostSection'
import Container from '../components/parts/Container'
import favicon from '../images/favicon.svg'

export const query = graphql`
  query BlogPostQuery($uid: String) {
    prismicPost(uid: { eq: $uid }) {
      id
      uid
      lang
      type
      # url
      tags
      data {
        date
        title {
          richText
        }
        body {
          ... on PrismicPostDataBodyText {
            slice_label
            slice_type
            primary {
              text {
                richText
              }
            }
          }
          ... on PrismicPostDataBodyQuote {
            slice_label
            slice_type
            primary {
              quote {
                richText
              }
            }
          }
          ... on PrismicPostDataBodyImageWithCaption {
            id
            slice_label
            slice_type
            primary {
              image {
                alt
                url
              }
              caption {
                richText
              }
            }
          }
          ... on PrismicPostDataBodyMarkdown {
            slice_label
            slice_type
            primary {
              markdown {
                richText
              }
            }
          }
          # ... on PrismicPostDataBodyEmbed {
          #   slice_label
          #   slice_type
          #   primary {
          #     url {
          #       raw
          #     }
          #   }
          # }
          ... on PrismicPostDataBodyHtml {
            id
            slice_label
            slice_type
            primary {
              html
            }
          }
        }
      }
    }
  }
`

const PostHeaderNav = styled(Container)`
  display: flex;
  margin-bottom: 30px;

  > a {
    border: 0;
    line-height: 0;
    text-decoration: none;
  }
`

// Display the title, date, and content of the Post
const PostDetail = ({ data, tags }) => {
  const title = RichText.asText(data.title.richText)

  return (
    <>
      <PostHeaderNav>
        <Link to="/posts/1">
          <img src={favicon} width="20" height="20" alt="logo" />
        </Link>
      </PostHeaderNav>
      <PostSection data={data} tags={tags} showDisqus title={title} />
    </>
  )
}

const Post = ({ data }) => {
  if (!data) return null

  const title = RichText.asText(data.prismicPost.data.title.richText)

  return (
    <Layout title={title}>
      <PostDetail data={data.prismicPost.data} tags={data.prismicPost.tags} />
    </Layout>
  )
}

export default Post
